import {mapGetters} from "vuex";

export default {
    data() {
        return {
            showSubmenu: false,
            loading: false,
            showModal: false
        };
    },
    computed: {
        ...mapGetters({
            list: "ownerList",
        }),
        name() {
            return this.product.reservation?.name || ''
        },
        title() {
            if (this.product.status === this.RESERVADO) {
                if(this.name == "")
                {
                    //return("Vas a eliminar un objecto reservado. ¿Seguro que quieres eliminarlo?")
                    return(this.$t("mylist.msgDeleteReserevedProduct"))
                }
                // return (
                //     "Vas a eliminar un objeto que " +
                //     this.name +
                //     " había reservado. ¿Seguro que quieres eliminarlo?"
                // );
                return this.$t("mylist.msgDeleteReservedProductWithName", {name: this.name})
            }
            if (this.product.status === this.REGALADO) {
                //return "En teoría ya te han regalado este objeto. ¿Seguro que quieres eliminarlo?";
                return this.$t("mylist.msgDeleteGiftedProduct")
            }
            //return "¿Seguro que quieres eliminar este producto de tu lista?";
            return this.$t("mylist.msgDeleteProduct")
        },
        subtitle() {
            if (this.product.status === this.RESERVADO) {
                if(this.name == "") {
                    //return "Avisaremos por email a la persona que lo había reservado."
                    return this.$t("mylist.msgDeleteReserevedProductEmailWarning")
                }
                //return "Le enviaremos un email para avisarle.";
                return this.$t("mylist.msgDeleteReserevedProductEmailWarning")
            }
            return null;
        },
    },
    methods: {
        close() {
            this.showSubmenu = false
        },
    },
    created() {
        this.PENDIENTE = 1;
        this.REGALADO = 2;
        this.RESERVADO = 3;
    },
}
